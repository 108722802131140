const env = String(process?.env?.ENV);
const brand = String(process.env.BRAND);

export const Config = {
	Env: {
		Is: env,
		IsLocal: env === 'local',
		IsDev: env === 'dev',
		IsQa: env === 'qa',
		IsStg: env === 'stg',
		IsPreview: env === 'preview',
		IsPrd: env === 'prd',
	},
	SitecoreApiKey: String(process?.env?.SITECORE_API_KEY),
	SitecoreGraphQLEndpoint: String(process?.env?.GRAPH_QL_ENDPOINT),
	Urls: {
		Cms: String(process.env.CMS_URL),
		Order: String(process.env.ORDER_URL),
		OrderRaw: String(process.env.ORDER_URL_RAW),
		Olo: String(process.env.OLO_URL),
	},
	Brand: brand,
	RevalidationPeriod: Number(process.env.REVALIDATION_PERIOD),
} as const;
